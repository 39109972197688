import { EBannerType } from 'shared/constants/banner';
import { TNewbuildingRentTrapExperimentGroup } from 'shared/selectors/abExperiments';

interface IGetBannerTypeParams {
  bannerType: EBannerType.RentNBListing | EBannerType.RentKpnCallback;
  experimentGroup: TNewbuildingRentTrapExperimentGroup;
}

export const getBannerType = ({ bannerType, experimentGroup }: IGetBannerTypeParams) => {
  const oldDesign = experimentGroup === 'B';

  switch (bannerType) {
    case EBannerType.RentNBListing:
      return oldDesign ? 'old_link' : 'new_link';
    case EBannerType.RentKpnCallback:
      return oldDesign ? 'old_callback' : 'new_callback';
  }
};
