import { NewbuildingKpnBannerContext, KpnBanner } from '@cian/frontend-newbuilding-kpn-banners';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { EBannerType } from 'shared/constants/banner';
import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { selectNewbuildingFlatSaleTrapGroup } from 'shared/selectors/abExperiments';
import { getUserId, selectDefaultPhoneNumber } from 'shared/selectors/callbackRightNow';
import { getGaLabel } from 'shared/selectors/newObject';
import { regionIdSelector } from 'shared/selectors/offerData/geo/regionIdSelector';
import { selectNewbuildingSuburbanTrapUrl } from 'shared/selectors/selectNewbuildingSuburbanTrapUrl';
import { getPrivacyPolicyUrl } from 'shared/utils/newbuildingRentBanner/getPrivacyPolicyUrl';

interface INewbuildingFlatSaleBannerContainerProps {
  bannerType: EBannerType.FlatSaleListing | EBannerType.FlatSaleKpnCallback;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NewbuildingFlatSaleBannerContainer = (props: INewbuildingFlatSaleBannerContainerProps) => {
  const context = useApplicationContext();

  const regionId = useSelector(regionIdSelector);
  const rentTrapUrl = useSelector(selectNewbuildingSuburbanTrapUrl);
  const defaultPhoneNumber = useSelector(selectDefaultPhoneNumber);
  const gaLabel = useSelector(getGaLabel);
  const userId = useSelector(getUserId);
  const flatSaleTrapGroup = useSelector(selectNewbuildingFlatSaleTrapGroup);

  const personalAgreementLink = React.useMemo(() => {
    return getPrivacyPolicyUrl(context.config);
  }, [context]);

  /* istanbul ignore next */
  const banner = flatSaleTrapGroup === 'B' ? 'flat_sale_link' : 'flat_sale_callback';

  /* istanbul ignore next */
  if (!rentTrapUrl) {
    return null;
  }

  return (
    <div data-testid="NewbuildingRentBannerContainer">
      <NewbuildingKpnBannerContext.Provider value={context}>
        <KpnBanner
          placeType="card"
          personalAgreementLink={personalAgreementLink}
          regionId={regionId}
          newobjectsListingUrl={rentTrapUrl}
          bannerType={banner}
          defaultPhoneNumber={defaultPhoneNumber}
          userId={userId}
          gaLabel={gaLabel}
        />
      </NewbuildingKpnBannerContext.Provider>
    </div>
  );
};
