const WEIGHT_STEP = 5;
const TRAPS_ARRAY_LENGTH = 20;
const TRAPS_ARRAY_NAME_LS = 'valuation_traps';

enum ETrapBannerName {
  MyHome = 'myHomeTrap',
  Realtor = 'realtorTrap',
}

interface ITrapsWeightFromLocalStorage {
  valuationTraps: ETrapBannerName[];
  lastIndex: number;
  trapWeight: number;
}

/** Функция для получения флага - показывать ли банер моего дома */
export const getMyHomeBanner = (trapWeightFromConfig: number | null): boolean => {
  // Если данных нет или документ undefined то ничего не показываем
  if (typeof document === 'undefined' || !trapWeightFromConfig) {
    return false;
  }

  const trapBanner = getValuationTrapElem(trapWeightFromConfig);

  // Возвращаем условие показа банера МД
  return trapBanner === ETrapBannerName.MyHome;
};

/**
 * Функция получения банера для показа
 * Функция получает/записывает данные в localStorage
 * Данные представляют собой масив очереди показа/индекс последнего показанного банера/Вес банеров моего дома
 * Возвращает ключ показываемо банера
 * */
function getValuationTrapElem(trapWeightFromConfig: number): ETrapBannerName {
  // Получаем данные из LS
  const trapWeightFromLocalStorage = getTrapsFromLocalStorage();

  // Если есть данные в LS
  if (trapWeightFromLocalStorage) {
    const { trapWeight, lastIndex, valuationTraps } = trapWeightFromLocalStorage;

    // И настройка RS не менялась
    if (trapWeight === trapWeightFromConfig) {
      // Проверяем индекс показанного банера
      // если последний, то начинаем показ сначала
      if (valuationTraps.length === lastIndex + 1) {
        // Передаем в LS индекс показанного банера
        setTrapsFromLocalStorage({
          valuationTraps,
          trapWeight,
          lastIndex: 0,
        });

        return valuationTraps[0];
      }

      // Если не последний - показываем следующий
      // Передаем в LS индекс показанного банера
      setTrapsFromLocalStorage({
        valuationTraps,
        trapWeight,
        lastIndex: lastIndex + 1,
      });

      return valuationTraps[lastIndex + 1];
    }
  }

  // Если данных в LS нет - создаем новую очередь показа
  const newValuationTraps = createTrapsWeightArray(trapWeightFromConfig);

  // Передаем в LS новую очередь банеров и новую настройку из RS
  setTrapsFromLocalStorage({
    valuationTraps: newValuationTraps,
    trapWeight: trapWeightFromConfig,
    lastIndex: 0,
  });

  return newValuationTraps[0];
}

/**
 * Функция создания массива банеров для ротации показа
 * Создает очередь из 20 показов банеров
 * Распределяет банеры в рандомном порядке в очереди
 * Колличество распределено в соответсткии с весом банера МД
 * */
function createTrapsWeightArray(myHomeTrapWeight: number): ETrapBannerName[] {
  // Получаем количество показов ловушки моего дома
  const myHomeBannerCount = myHomeTrapWeight / WEIGHT_STEP;

  // Наполняем массив очередью показа банеров
  const trapsWeightArray: ETrapBannerName[] = [];
  for (let i = 0; i < TRAPS_ARRAY_LENGTH; i++) {
    if (i < myHomeBannerCount) {
      trapsWeightArray.push(ETrapBannerName.MyHome);
    } else {
      trapsWeightArray.push(ETrapBannerName.Realtor);
    }
  }

  // Перемешиваем массив для коректного показа
  return trapsWeightArray.sort(() => Math.random() - 0.5);
}

/** Функция получения данных из localStorage */
function getTrapsFromLocalStorage(): ITrapsWeightFromLocalStorage | null {
  return JSON.parse(localStorage.getItem(TRAPS_ARRAY_NAME_LS) as string);
}

/** Функция записи данных в localStorage */
function setTrapsFromLocalStorage(params: ITrapsWeightFromLocalStorage): void {
  localStorage.setItem(TRAPS_ARRAY_NAME_LS, JSON.stringify(params));
}
