import * as React from 'react';

import { getCloseFromCookie } from 'shared/containers/valuationTraps/utils/getCloseFromCookie';
import { setCloseCookies } from 'shared/containers/valuationTraps/utils/setCloseCookies';

export const useCloseMyHomeHomeownerTrap = () => {
  const [isClose, setClose] = React.useState<boolean>(true);

  const handleClose = React.useCallback(() => {
    setClose(true);
    setCloseCookies();
  }, [setClose]);

  React.useEffect(() => {
    const isCloseFromCookie = getCloseFromCookie();

    setClose(!!isCloseFromCookie);
  }, [setClose]);

  return {
    isClose,
    handleClose,
  };
};
