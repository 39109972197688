import { ArticleHeading4, ArticleParagraph2 } from '@cian/ui-kit';
import { IconActionClose12 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

interface IMyHomeHomownerTrapProps {
  onClick: () => void;
  onClose: () => void;
}

export const MyHomeHomownerTrap: React.FC<IMyHomeHomownerTrapProps> = ({ onClick, onClose }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <div className={styles['title']}>
          <ArticleHeading4 color="text-primary-default">Мой дом</ArticleHeading4>
          <ArticleParagraph2 color="text-primary-default">
            Оценка стоимости и тренды рынка: расскажем всё, что нужно для выгодной сдачи вашего жилья и поиска нового
          </ArticleParagraph2>

          <ArticleParagraph2 color="text-main-default" onClick={onClick}>
            Добавить в Мой дом
          </ArticleParagraph2>
        </div>

        <div className={styles['icon']} onClick={onClose}>
          <IconActionClose12 color="icon-secondary-default" />
        </div>
      </div>
    </div>
  );
};
