import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { MyHomeTrapBanner } from 'shared/components/MyHomeTrap';
import { addressSelector } from 'shared/selectors';

import { trackShowMyHomeBanner, trackClickMyHomeBanner } from '../utils/tracking';

export const MyHomeTrapContainer: React.FC = () => {
  const address = useSelector(addressSelector);
  const addressString = address.map(addr => addr.fullName).join(', ');

  const myHomeLink = `/my-home/?from=offer_all_banner&address=${addressString}`;

  return (
    <ActionAfterViewed callback={trackShowMyHomeBanner}>
      <MyHomeTrapBanner href={myHomeLink} onClick={trackClickMyHomeBanner} />
    </ActionAfterViewed>
  );
};
