import { UIHeading1, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

interface IDiscountMortgageBannerProps {
  url: string;
  onClick: () => void;
}

export const DiscountMortgageBanner = ({ url, onClick }: IDiscountMortgageBannerProps) => {
  return (
    <div className={style['container']}>
      <a
        className={style['banner']}
        href={url}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
        data-testid="DiscountMortgageBanner"
      >
        <div className={style['content']}>
          <div className={style['text']}>
            <UIHeading1>Льготная ипотека закончится 1 июля</UIHeading1>
            <UIText1>
              Успейте воспользоваться выгодной ставкой&nbsp;— <br />
              ищите пурпурные значки в&nbsp;новостройках
            </UIText1>
          </div>
          <div className={style['label']} />
          <div className={style['house-1']} />
          <div className={style['house-2']} />
          <div className={style['coin']} />
        </div>
      </a>
    </div>
  );
};
