import { trackBannerShow } from '../../containers/valuationTraps/utils/tracking';
import { offerSelector, selectSoprApi, selectTrackingUser } from '../../selectors';

import type { IThunkActionCreator } from '../../store';

export const trackInViewPort = (): IThunkActionCreator => {
  return (dispatch, getState) => {
    const state = getState();

    const { id: offerId } = offerSelector(state);
    const soprApi = selectSoprApi(state);
    const { userId, abGroup } = selectTrackingUser(state);

    trackBannerShow({
      abGroup,
      userId,
      soprApi,
      offerId,
      blockType: 'demand',
    });
  };
};
