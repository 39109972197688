import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { getMortgageBannerUrl } from 'shared/selectors/discountMortgage';

import { trackBannerClick, trackBannerShow } from './tracking';
import { DiscountMortgageBanner } from '../../components/DiscountMortgageBanner';

export const DiscountMortgageBannerContainer = () => {
  const url = useSelector(getMortgageBannerUrl);

  return (
    <ActionAfterViewObserver callback={trackBannerShow} triggerOnce>
      <DiscountMortgageBanner url={url} onClick={trackBannerClick} />
    </ActionAfterViewObserver>
  );
};
