import * as React from 'react';
import { useDispatch } from 'react-redux';

import { addToMyHomeObject } from 'shared/actions/valuationTraps';
import { trackClickAddToMyHome } from 'shared/containers/valuationTraps/utils/tracking';

export const useAddToMyHomeObject = () => {
  const dispatch = useDispatch();

  const handleClick = React.useCallback(() => {
    trackClickAddToMyHome();
    dispatch(addToMyHomeObject());
  }, [dispatch]);

  return {
    handleClick,
  };
};
