import { ca } from '@cian/analytics';

/**
 * Расположение ловушки
 * Ловушка размещена в жилой коммерческой карточке
 * */
export enum ETrapLocations {
  Living = 'living',
  Commercial = 'commercial',
}

export const trackBannerClick = (banner_location: ETrapLocations) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Click',
    label: 'Banner',
    page: {
      pageType: 'Card',
      device_type: 'desktop',
      extra: {
        banner_location,
      },
    },
  });
};
