import { ca } from '@cian/analytics';

export function trackShowMyHomeBanner() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'myhome',
    action: 'show_sopr',
    label: 'add_address',
    page: {
      extra: {
        trap_type: 'MyHomeKo',
      },
    },
    useLastProducts: true,
  });
}

export function trackClickMyHomeBanner() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'myhome',
    action: 'click',
    label: `add_address`,
    page: {
      extra: {
        trap_type: 'MyHomeKo',
      },
    },
    useLastProducts: true,
  });
}
