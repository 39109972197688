import { ca } from '@cian/analytics';

export function trackBannerClick(): void {
  ca('eventSite', {
    action: 'click_sopr',
    category: 'Card',
    label: 'nb_mortgage_banner',
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackBannerShow(): void {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'Card',
    label: 'nb_mortgage_banner',
    name: 'oldevent',
    useLastProducts: true,
  });
}
