import { parseCookies } from '@cian/utils/lib/shared/cookie';

import { sendTeaserEvent } from '../../../../services/teaser';
import { ITeaserEvent, TTeaserBlockType } from '../../../../types/teaser';
import { getViewportDimensions } from '../../../../utils/getViewportDimensions';

export interface ISendTeaserParams {
  userId?: number | null;
  abGroup: number;
  offerId: number;
  soprApi: string;
  blockType: TTeaserBlockType;
}

export function trackBannerShow(params: ISendTeaserParams) {
  try {
    const event: ITeaserEvent = {
      user_id: params.userId,
      pageType: 'Card',
      device_type: 'desktop',
      ab_group: params.abGroup,
      viewport: getViewportDimensions(),
      cid: parseCookies(document.cookie)['_CIAN_GK'],
      items: [
        {
          announcement_id: params.offerId,
          block_type: params.blockType,
          isTeaserShow: true,
          demand_location: 'after_similar',
        },
      ],
    };

    sendTeaserEvent(params.soprApi, event);
    // eslint-disable-next-line no-empty
  } catch (e) {}
}
