import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import { EVariant, RealtorRequestTrapV2 } from '@cian/demand-trap-widget';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { trackInViewPort } from '../../../actions/requestDemand';
import { ETrapLocations, trackBannerClick } from '../utils/tracking';

export const RealtorRequestCommercialTrapContainer: React.FC = () => {
  const dispatch = useDispatch();

  const handleClickLink = React.useCallback(() => trackBannerClick(ETrapLocations.Commercial), []);

  return (
    <ActionAfterViewed callback={() => dispatch(trackInViewPort())}>
      <RealtorRequestTrapV2 variant={EVariant.C} onClick={handleClickLink} href="/application-form/" openBlank />
    </ActionAfterViewed>
  );
};

RealtorRequestCommercialTrapContainer.displayName = 'RealtorRequestCommercialTrapContainer';
