import * as React from 'react';
import { useSelector } from 'react-redux';

import { MyHomeHomownerTrap } from 'shared/components/MyHomeTrap/MyHomeHomownerTrap/MyHomeHomownerTrap';
import { useAddToMyHomeObject } from 'shared/containers/valuationTraps/hook/useAddToMyHomeObject';
import { useCloseMyHomeHomeownerTrap } from 'shared/containers/valuationTraps/hook/useCloseMyHomeHomeownerTrap';
import { selectIsAgent, selectIsOfferOwnedByUser } from 'shared/selectors';
import { selectIsMyHomeObjectsExists } from 'shared/selectors/valuationTraps';

export const MyHomeHomeownerTrapContainer: React.FC = () => {
  const isOfferByUser = useSelector(selectIsOfferOwnedByUser);
  const isAgent = useSelector(selectIsAgent);
  const isMyHomeObjectsExists = useSelector(selectIsMyHomeObjectsExists);

  const { handleClick } = useAddToMyHomeObject();
  const { isClose, handleClose } = useCloseMyHomeHomeownerTrap();

  if (!isOfferByUser || isMyHomeObjectsExists || isAgent || isClose) {
    return null;
  }

  return <MyHomeHomownerTrap onClick={handleClick} onClose={handleClose} />;
};
