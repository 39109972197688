import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import { RealtorRequestTrapV3 } from '@cian/demand-trap-widget';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { trackInViewPort } from '../../../actions/requestDemand';
import { trackBannerClick, ETrapLocations } from '../utils/tracking';

export const RealtorRequestTrapContainer: React.FC<React.PropsWithChildren<{ withoutBorders?: boolean }>> = ({
  withoutBorders = false,
}) => {
  const dispatch = useDispatch();

  const handleClickLink = React.useCallback(() => trackBannerClick(ETrapLocations.Living), []);

  return (
    <ActionAfterViewed callback={() => dispatch(trackInViewPort())}>
      <RealtorRequestTrapV3
        onClick={handleClickLink}
        href="/application-form/"
        withoutBorders={withoutBorders}
        openBlank
      />
    </ActionAfterViewed>
  );
};

RealtorRequestTrapContainer.displayName = 'RealtorRequestTrapContainer';
