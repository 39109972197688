import { ca } from '@cian/analytics';

export function trackClickAddToMyHome() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'MyHomeFlat',
    action: 'Click',
    label: `Trap`,
    useLastProducts: true,
  });
}
