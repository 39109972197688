/* eslint-disable @typescript-eslint/no-explicit-any */
import { ITeaserEvent } from '../../types/teaser';

export const sendTeaserEvent = (soprApi: string, request: ITeaserEvent): Promise<any> => {
  return fetch(`${soprApi}teaser/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });
};
